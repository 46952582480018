<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <!--      <div v-if="!serverId && isLoading">-->
      <div v-if="!diskId && isLoading">
        <base-loader class="stack-detail-view__loader-item" />
      </div>
      <page-block v-else class="vps-detail-view__block">
        <main-card>
          <div class="addons">
            <div class="addons__item">
              <div class="addons__label medium-title">
                {{ $t('attach') }}
              </div>
              <plain-button
                v-if="!instance && tariff.status.code !== 27"
                color="primary"
                class="tariff-card__prolong"
                @click="attachDisk(instance, id)"
              >
                {{ $t('attachTo') }}
              </plain-button>
              <router-link
                :to="{ name: 'Server', params: { serverid: instance.id } }"
                class="addons__value medium-text"
              >
                {{ instance.name }}
              </router-link>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('size') }}</div>
              <div class="addons__value medium-text">{{ tariff.size }} ГБ</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('type') }}</div>
              <div class="addons__value medium-text">
                <div class="addons">
                  {{ tariff.volume_type }}
                  {{ tariff.bootable === 'true' ? 'Загрузочный' : null }}
                </div>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import AttachDisk from '@/mixins/attachDisk';
import PageBlock from '@/layouts/Stack/pages/Information/components/PageBlock.vue';
export default {
  name: 'DiskView',
  components: {
    MainCard,
    PageBlock,
  },
  mixins: [providerChange, AttachDisk],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    serverId() {
      return this.currentDisk.attachments.length > 0
        ? this.currentDisk.attachments[0].server_id
        : '';
      // return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    id() {
      // console.log(this.servers);
      return this.tariff.id;
    },
    currentDisk() {
      return this.disks.find(disk => disk.id === this.id);
    },
    instance() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverId) || '';
    },
    diskId() {
      return this.tariff.id;
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "attach": "Подключен",
      "attachTo": "Подключить к серверу:",
      "size": "Размер",
      "type": "Тип диска",
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память",
      "sure": {
      "confirm": "Подключить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
